import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import axios from 'axios';
import qs from 'querystring';
import { pageDescriptions } from '../schemas';

const active = { color: '#fff', backgroundColor: '#080808' };

const Modules = () => {
  const location = useLocation();

  return (
    <>
      {Object.keys(pageDescriptions).map((id) => {
        if (id === 'debug') {
          return null;
        }
        return (
          <ModuleLink key={id} to={id} location={location}>
            {pageDescriptions[id].description}
          </ModuleLink>
        );
      })}
    </>
  );
};

type ModuleLinkProps = {
  children: Array<React.Children>,
  to: string,
  location: Object
};

const ModuleLink = (props: ModuleLinkProps) => {
  const {
    location: { search }
  } = props;
  const all = qs.parse(search.substring(1));
  const params = {};
  Object.keys(all).forEach((k) => {
    if (k.startsWith('config.')) {
      params[k] = all[k];
    }
  });
  const to = {
    pathname: props.to,
    search: qs.stringify(params)
  };
  return (
    <li>
      <NavLink activeStyle={active} to={to}>
        {props.children}
      </NavLink>
    </li>
  );
};

export default Modules;
