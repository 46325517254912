// @flow

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Form from 'react-jsonschema-form';
import queryString from 'query-string';
import dot from 'dot-object';
import {
  contentIndexFetch,
  metadataIndexFetch,
  correspondenceSubmission,
  schemaMap
} from './schemas';
import Results from './Results';

type Props = {
  location: Object,
  match: Object
};

const FormContainer = (props: Props) => {
  const history = useHistory();
  let [formData, setFormData] = useState({});
  const [def, setDef] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    location: { search },
    match: {
      params: { module }
    }
  } = props;

  useEffect(() => {
    if (module) {
      setDef(schemaMap[module]);
      setResults([]);
      setError(undefined);
    }
  }, [module]);

  if (!def) return 'Loading...';

  const params = castParams(queryString.parse(search));
  const dotParams = dot.object(params);
  const { schema, uiSchema } = def;
  formData = { ...dotParams, ...formData, module };

  const onSubmit = async ({ formData }, e) => {
    const { module } = formData;

    setLoading(true);
    updateUrlParams(history, formData);
    setFormData(formData);

    const { data } = await axios.post(`/api/${module}`, formData);

    if (data.error) {
      setResults([]);
      setError(data.error);
    } else {
      setResults(data);
      setError(undefined);
    }

    setLoading(false);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-3 col-md-4 col-sm-5 col-xs-12">
          <Form
            schema={schema}
            uiSchema={uiSchema}
            onSubmit={onSubmit}
            formData={formData}
          />
        </div>
        <div className="col-lg-9 col-md-8 col-sm-7 col-xs-12">
          <span className="label label-success">{results.length} items</span>
          <hr />
          {loading && 'Loading...'}
          {error && (
            <div class="alert alert-danger" role="alert">
              {JSON.stringify(error, null, 2)}
            </div>
          )}
          {results && (
            <Results items={results} module={module} formData={formData} />
          )}
        </div>
      </div>
    </div>
  );
};

const castParams = (params: Object) => {
  const casted = {};
  Object.keys(params).forEach((key) => {
    const val = params[key];
    if (/^\d+$/.test(val)) {
      casted[key] = parseInt(val, 10);
    } else if (['true', 'false'].includes(val.toLowerCase())) {
      casted[key] = val.toLowerCase() === 'true';
    } else {
      casted[key] = val;
    }
  });
  return casted;
};

const updateUrlParams = (history: Object, params: Object) => {
  Object.keys(params).forEach((k) => {
    if (!Object.keys(params[k]).length) {
      delete params[k];
    }
  });
  const qs = dot.dot(params);
  history.push(`?${queryString.stringify(qs)}`, {});
};

export default FormContainer;
