import React, { useState, useEffect, useRef } from 'react';
import ReactJson from 'react-json-view';

const Results = ({ items, module, formData }) => {
  const [src, setSrc] = useState('');
  const [width, setWidth] = useState(0);
  const [vote, setVote] = useState(0);
  let ref = useRef(null);

  useEffect(() => {
    setWidth(ref.current ? ref.current.offsetWidth : 0);
  }, []);

  const updateVote = (e) => {
    setVote(e.target.value);
  };

  // extremely dirty (but only) way to log stats
  const logStat = (e, entry, type) => {
    let url = formData.config.serverUrl;
    const http = url.substring(0, 7);
    url = url.slice(7); // slice off http://
    const baseUrl = `${http}${formData.config.accountId}.${url}/action/logStatistic?statistic=${type}&client=${formData.config.accountId}`;
    let additionalParams = '';
    if (type === 'entry-view') {
      additionalParams = `&name=${entry['Entry:ID']}&category=${entry['Entry:CategoryID']}`;
    }
    if (type === 'entry-vote') {
      additionalParams = `&name=${entry['Entry:ID']}&vote=${vote}`;
    }
    if (type === 'location-search') {
      additionalParams = `&searchtext=${formData.filter.searchText}&searchconf=${entry['MemoryEngine:Score']}`;
    }
    if (type === 'location-view') {
      additionalParams = `&category=${entry.ID}`;
    }
    setSrc(`${baseUrl}${additionalParams}`);
    setTimeout(() => {
      setSrc('');
    }, 1000);
  };

  const content = module === 'content-index-fetch';
  const meta = module === 'metadata-index-fetch';
  const isSearch = formData?.filter?.searchText;

  return (
    <div className="grid resultWrap" ref={ref}>
      {items.map((li, i) => {
        const { mData, mItemID } = li;
        /* this is a bit of a performance hit to be honest, may be a better way.
             400 from grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)) */
        const collapsed = i >= Math.floor(width / 400);

        const title = (mData && mData['Entry:Part:question:Data']) || mItemID;

        return (
          <div key={i}>
            <ReactJson
              src={li}
              name={title}
              collapseStringsAfterLength={20}
              collapsed={collapsed}
            />
            {content && (
              <>
                <hr />
                <div className="row">
                  <div className="col-lg-6">
                    <button
                      className="btn btn-default"
                      onClick={(e) => logStat(e, mData, 'entry-view')}>
                      log view
                    </button>
                    <button
                      disabled={!isSearch}
                      className="btn btn-default"
                      onClick={(e) => logStat(e, mData, 'location-search')}>
                      log search
                    </button>
                  </div>
                  <div className="col-lg-6">
                    <div className="input-group">
                      <input
                        type="number"
                        min="0" // can be 0-5, 0 is negative, anything 1 & above is positive
                        max="5"
                        className="form-control"
                        onChange={updateVote}
                      />
                      <span className="input-group-btn">
                        <button
                          className="btn btn-default"
                          onClick={(e) => logStat(e, mData, 'entry-vote')}>
                          log vote
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
            {meta &&
              (mItemID?.includes('Category:') ||
                mItemID?.includes('Group:')) && (
                <>
                  <hr />
                  <div className="center-block fit">
                    <button
                      className="btn btn-default"
                      onClick={(e) => logStat(e, mData, 'location-view')}>
                      log location view
                    </button>
                  </div>
                </>
              )}
          </div>
        );
      })}
      <img
        src={src}
        alt="hiddenimg"
        style={{ height: 1, width: 1, display: 'none' }}></img>
    </div>
  );
};

export default Results;
