import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Modules from './Modules';

const active = { color: '#fff', backgroundColor: '#080808' };

const Navigation = (mods) => {
  const location = useLocation();

  return (
    <nav className="navbar navbar-inverse" style={{ borderRadius: 0 }}>
      <div className="container-fluid">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#navbar"
            aria-expanded="false"
            aria-controls="navbar">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <NavLink
            className="navbar-brand"
            to={{ pathname: '/', search: location.search }}>
            QA Console
          </NavLink>
        </div>
        <div id="navbar" className="collapse navbar-collapse">
          <ul className="nav navbar-nav">
            <Modules />
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
