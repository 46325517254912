export const pageDescriptions = {
  'content-index-fetch': {
    description: 'Content Index Fetch'
  },
  'correspondence-submission': {
    description: 'Correspondence Submission'
  },
  'metadata-index-fetch': { description: 'Metadata Index Fetch' }
};

export const contentIndexFetch = {
  schema: {
    properties: {
      config: {
        type: 'object',
        title: 'Content index fetch',
        required: ['serverUrl', 'accountId', 'indexId'],
        properties: {
          serverUrl: {
            type: 'string',
            description:
              'e.g. http://dev5.dev.xversal.net:19200 or (http|https)://alpha96.metafaq.com',
            title: 'Server URL'
          },
          accountId: {
            type: 'string',
            title: 'Account ID'
          },
          indexId: {
            type: 'string',
            title: 'Index ID'
          }
        }
      },
      filter: {
        type: 'object',
        title: 'Advanced filtering',
        required: ['resultsPerPage'],
        properties: {
          searchText: {
            type: 'string',
            title: 'Search Text',
            default: ''
          },
          relatedEntryID: {
            type: 'string',
            title: 'Related Search ID',
            default: ''
          },
          resultsPerPage: {
            type: 'integer',
            title: 'Results Per Page',
            default: 100
          },
          extraItems: {
            type: 'string',
            title: 'Request extra data',
            description:
              'comma separated, e.g. Spider:Vocabulary:Vocab1:TermIDs, Spider:Vocabulary:Vocab2:TermIDs',
            default: ''
          },
          arrayOfConditionals: {
            title: 'Add new filter',
            type: 'array',
            items: {
              type: 'object',
              description: 'Filter sub-group',
              properties: {
                filterKeyId: {
                  type: 'string',
                  title: 'Filter Key ID'
                }
              },
              dependencies: {
                filterKeyId: {
                  properties: {
                    filterBy: {
                      type: 'string',
                      title: 'Filter Type',
                      enum: [
                        'Exists',
                        'Equal',
                        'Greater',
                        'Less',
                        'Same',
                        'Only',
                        'All',
                        'Any'
                      ]
                    },
                    filterKeyValues: {
                      type: 'string',
                      title: 'Filter Key Values'
                    }
                  },
                  required: ['filterBy', 'filterKeyValues']
                }
              }
            }
          }
        },
        dependencies: {
          filterKeyId: ['filterKeyValues'],
          filterKeyValues: ['filterKeyId']
        }
      }
    }
  },
  uiSchema: {}
};

export const metadataIndexFetch = {
  schema: {
    type: 'object',
    properties: {
      config: {
        type: 'object',
        title: 'Metadata index fetch',
        required: ['serverUrl', 'accountId', 'indexId'],
        properties: {
          serverUrl: {
            type: 'string',
            description:
              'e.g. http://dev5.dev.xversal.net:19200 or (http|https)://alpha96.metafaq.com',
            title: 'Server URL'
          },
          accountId: {
            type: 'string',
            title: 'Account ID'
          },
          indexId: {
            type: 'string',
            title: 'Index ID'
          }
        }
      }
    }
  },
  uiSchema: {}
};

export const correspondenceSubmission = {
  schema: {
    type: 'object',
    properties: {
      config: {
        type: 'object',
        title: 'Correspondence submission',
        required: ['serverUrl', 'accountId', 'categoryId', 'messageType'],
        properties: {
          serverUrl: {
            type: 'string',
            description:
              'e.g. http://dev5.dev.xversal.net:19200 or (http|https)://alpha96.metafaq.com',
            title: 'Server URL'
          },
          accountId: {
            type: 'string',
            title: 'Account ID'
          },

          categoryId: {
            type: 'string',
            title: 'Category ID'
          },
          messageType: {
            type: 'string',
            title: 'Message Type',
            enum: ['Message', 'Feedback']
          },
          entryId: {
            type: 'string',
            title: 'Entry Ref'
          }
        }
      },
      statistics: {
        type: 'object',
        title: 'Statistics logging',
        properties: {
          userGroupId: {
            type: 'string',
            title: 'User Group ID',
            default: ''
          }
        }
      },
      message: {
        type: 'object',
        title: 'Message details',
        required: ['messageText'],
        properties: {
          emailAddress: {
            type: 'string',
            title: 'Email Address',
            format: 'email'
          },
          messageText: {
            type: 'string',
            title: 'Message Text'
          }
        }
      }
    }
  },
  uiSchema: {
    message: {
      messageText: {
        'ui:widget': 'textarea'
      }
    }
  }
};

export const schemaMap = {
  'content-index-fetch': contentIndexFetch,
  'correspondence-submission': correspondenceSubmission,
  'metadata-index-fetch': metadataIndexFetch
};
