import React, { Component } from 'react';

export default class ErrorBoundary extends Component {
  state = {
    errorMessage: ''
  };

  static getDerivedStateFromError(error) {
    return { errorMessage: error.toString() };
  }

  componentDidCatch(error, info) {
    this.logErrorToServices(error.toString(), info.componentStack);
  }

  logErrorToServices = console.log;
  render() {
    if (this.state.errorMessage) {
      return (
        <div className="container">
          <div className="row">
            <div class="well">
              <p>{this.state.errorMessage}</p>
              <button
                className="btn btn-primary"
                onClick={() => window.location.reload(true)}>
                Refresh
              </button>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
