import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import Navigation from './Navigation/Navigation';
import Home from './Home';
import FormContainer from './FormContainer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const App = () => {
  return (
    <BrowserRouter>
      <Navigation />
      <ErrorBoundary>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/:module" component={FormContainer}></Route>
        </Switch>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default App;
